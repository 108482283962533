<template>
    <div class="list">
        <a-spin :spinning="spinning">
            <table class="table" v-if="list.length != 0">
                <colgroup>
                    <col width='100'>
                    <col width='120'>
                    <col width='180'>
                    <col width='200'>
                    <col width='125'>
                    <col width='160'>
                </colgroup>
                <thead class="table-thead">
                    <tr>
                        <th class="th1">序号</th>
                        <th>头像</th>
                        <th>用户名</th>
                        <th>职称</th>
                        <th>发帖数</th>
                        <th>积分</th>
                        <!-- <th>最后回帖时间</th> -->
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr v-for="( item , index)  in list" :key="item.Id">
                        <td class="index">
                            <span :class="'index' + (index + 1)">{{index + 1}}</span>
                        </td>
                        <td>
                            <a-avatar icon="user" :src="item.ImageUrl" />
                        </td>
                        <td>
                            <span class="dot" >{{item.Nickname || item.TrueName}}</span>
                        </td>
                        <td>
                            <span class="dot" >{{item.Title}}</span>
                        </td>
                        <td>
                            <div>{{item.Posts}}</div>
                        </td>
                        <td>
                            <div>{{item.Integral}}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <a-empty v-if="list.length == 0"/>
        </a-spin>
        <Pagination
            :pageSize="params.pageSize"
            :total="total"
            @pageChange="pageChange"
            v-if="list.length>0"
            :current="params.pageIndex"
        />
    </div>
</template>
<script>
import Pagination from '~/components/common/Pagination'
import { mapState, mapActions, mapMutations } from 'vuex'
// 接口
import { GetUserList } from '@/api/forum'
export default {
    props:{
        tabId:Number,
        subjectIds:String,
        keyword:String
    },
    components:{
        Pagination
    },
    data() {
        return {
            list:[],
            params:{
                pageIndex:1,
                pageSize:10,
                orderBy:'Posts desc',
                key:'',
            },
            total:0,
            spinning:true,

            cate: [],
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo,
            subjectListNoTree:state => state.model.subjectListNoTree,
        }),
    },
    watch:{
        // tabId:{
        //     handler(newval,oldval){
               
        //         if(newval == 3 || newval == 4){
        //             if(newval == 3 ){
        //                 this.params.orderBy = 'Posts desc'
        //             }else{
        //                 this.params.orderBy = 'Integral desc'
        //             }
        //             this.params.pageIndex = 1
        //             this.getList()
        //         }
        //     }
        // },
        subjectIds:{
            handler(newval,oldval){
                if(this.tabId == 3 || this.tabId == 4){
                    this.params.subjectId = newval
                    if(this.tabId == 3){
                        this.params.orderBy = 'Posts desc,Integral desc'
                    }
                    if(this.tabId == 4){
                        this.params.orderBy = 'Integral desc,Posts desc'
                    }
                    this.getList()
                }
                
            },
            immediate:true
        },
        keyword:{
            handler(newval,oldval){
                if(this.tabId == 3 || this.tabId == 4){
                    this.params.key = newval
                    this.getList()
                }
            }
        }
    },
    methods:{
        pageChange({page,pageSize}){
            this.params.pageIndex = page
            this.getList()
        },
        // 详情
        goDetail(item){
            this.$router.push({name:'forum-info',params:{id:item.Id}})
        },
        // 获取列表
        async getList(){
            this.spinning = true
            const { data , total } = await GetUserList(this.params)
            this.list = data
            this.total = total
            this.spinning = false
        }
    },
    created(){
        
    }
    
}
</script>
<style scoped lang="less">
@import url('~@/assets/css/personal.less');
td{
    text-align: center !important;
}
.title{
    text-align: left !important;
    display: flex;
    &>span{
        cursor: pointer;
        height: 1.5em;
        &:hover{
            color: #f49133;
        }
    }
    .tag_wrap{
        flex-shrink: 0;
        min-width: 60px;
        padding-right: 5px;
        span{
            display: inline-block;
            padding: 0 10px;
            line-height: 30px;
            border-radius: 10px 0 10px 0;
            background-color: #f49133;
            color: #fff;
        }
    }

}
.time{
    color: rgb(136, 136, 136) ;
}
:deep(.pagenation){
    padding-top: 45px;
    margin-top: 0;
}
.index{
    span{
        min-width: 24px;
        border-radius: 5px;
        height: 24px;
        line-height: 24px;
        background-color: rgb(245, 245, 245);
        color: rgb(136, 136, 136);
        display: inline-block;
    }
    .index1{
        background-color: #ff0000;
        color: #fff;
    }
    .index2{
        background-color: #ff8181;
        color: #fff;
    }
    .index3{
        background-color: #ff9999;
        color: #fff;
    }
}


</style>