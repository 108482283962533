<template>
    <div class="search">
        <a-select 
            size="large"
            placeholder="选择类型"
            v-model="form.Type"
        >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option v-for="option in options" :key="option.value" :value="option.value">
                {{option.name}}
            </a-select-option>
        </a-select>
        <div class="line"></div>
        <a-input placeholder="" v-model="form.value" allow-clear @keyup.enter="search"/>
         <a-button :loading="loading" @click="search">检索</a-button>
    </div>
</template>
<script>
export default {
    props:{
        loading:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            options:[
                {
                    name:'帖子',
                    value:0
                },
                {
                    name:'用户',
                    value:1
                }
            ],
            form:{
                Type:0,
                value:''
            }
        }
    },
    methods:{
        search(){
            this.$emit('search',this.form)
        }
    }
}
</script>
<style lang="less" scoped>
.search{
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    position: relative;
    margin-bottom: 35px;
    .line{
        width: 1PX;
        height: 30px;
        background-color: #d8d8d8;
        left: 250px;
        height: 30px;
        flex-shrink: 0;
    }
    :deep(.ant-select){
        width: 250px;
    }
    :deep(.ant-select-selection-selected-value){
        font-size: 18px;
        color: rgb(187, 187, 187);
    }
    :deep(.ant-input){
        height: 48px;
        line-height: 48px;
        background-color: #f5f5f5;
    }
    :deep(.ant-btn){
        min-width: 120px;
        font-size: 18px;
        height: 50px;
        line-height: 50px;
        color: #000;
        background-color: #79f9d9;
        letter-spacing: 0;
        font-weight: bold;
        border-color: #79f9d9;
        &:focus,
        &:hover {
            background-color: #79f9d9;
            border-color: #79f9d9;
            color: #000;
            box-shadow: 0 0 10px 0 rgb(121 , 249 , 217 , .8);
        }
    }
}
</style>