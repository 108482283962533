<template>
    <div class="list">
        <a-spin :spinning="spinning">
            <table class="table" v-if="list.length > 0">
                <colgroup>
                    <col width='100'>
                    <col width='650'>
                    <col width='260'>
                    <col width='150'>
                    <col width='150'>
                    <col width='150'>
                    <col width='280' v-if="userInfo.IsSpecial">
                </colgroup>
                <thead class="table-thead">
                    <tr>
                        <th class="th1">序号</th>
                        <th>名称</th>
                        <th>主题</th>
                        <th>发帖人</th>
                        <th>回复数量</th>
                        <th v-if="tabId == 5 ">发帖时间</th>
                        <th v-else>回复时间</th>
                        <th v-if="userInfo.IsSpecial">操作</th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr v-for="( item , index)  in list" :key="item.Id">
                        <td class="index">
                            <span :class="'index' + (index + 1)">{{index + 1}}</span>
                        </td>
                        <td>
                            <div class="title">
                                <div class="tag_wrap">
                                    <span v-if="item.Type == 1">精选</span>
                                </div>
                                <span class="dot" @click="goDetail(item)">{{item.Title}}</span>
                            </div>
                        </td>
                        <td>
                            <div>{{subjectListNoTree.find(subjectitem => subjectitem.Id == item.SubjectId)?.Title}}</div>
                        </td>
                        <td>
                            <div>{{item.UserInfo?.Nickname || item.UserInfo?.TrueName}}</div>
                        </td>
                        <td>
                            <div>{{item.Reply}}</div>
                        </td>
                        <td>
                            <div class="time" v-if="tabId == 5 && item.CreateTime">{{item.CreateTime.split(' ')[0].split('/').join('-')}}</div>
                            <div class="time" v-else>{{item.LastTime.split(' ')[0].split('/').join('-')}}</div>
                        </td>
                        <td class="handle" v-if="userInfo.IsSpecial ">
                            <template>
                                <div >
                                    
                                    <!-- 加精 -->
                                    <a-popconfirm
                                        :title="item.Type == 1 ? '是否取消该帖子为精选?':'是否将该帖子设置为精选？'  "
                                        ok-text="是"
                                        cancel-text="否"
                                        @confirm="confirmRefinePosts(item)"
                                        v-if="tabId != 5"
                                    >
                                        <a-button :loading="item.goodLoading" size="small"> 
                                            <!-- {{item.Type == 1 ? '取消加精':'加精'}}     -->
                                            <template v-if="item.Type == 1 ">取消加精</template>
                                            <template v-else>加精</template>
                                        </a-button>
                                    </a-popconfirm>
                                    <!-- 热门 -->
                                    <a-popconfirm
                                        :title=" item.IsHot ? '是否取消该帖子为热门?':'是否将该帖子设置为热门?' "
                                        ok-text="是"
                                        cancel-text="否"
                                        @confirm="confirmHotPosts(item)"
                                        v-if="tabId != 5 "
                                    >
                                        <a-button :loading="item.hotLoading" size="small" >
                                            <!-- {{item.IsHot  ? '取消热门':'热门'}} -->
                                            <template v-if="item.IsHot ">取消热门</template>
                                            <template v-else>热门</template>
                                        </a-button>
                                    </a-popconfirm>
                                    <!-- 审核 -->
                                    <a-popconfirm
                                        title="是否审核通过该帖子?"
                                        ok-text="是"
                                        cancel-text="否"
                                        @confirm="confirmPassPosts(item)"
                                        v-if="tabId == 5"
                                    >
                                        <a-button :loading="item.auditLoading" size="small">审核</a-button>
                                    </a-popconfirm>
                                    <!-- 删除 -->
                                    <a-popconfirm
                                        title="是否删除该帖子?"
                                        ok-text="是"
                                        cancel-text="否"
                                        @confirm="confirmDeletePosts(item)"
                                    >
                                        <a-button :loading="item.deleteLoading" size="small"  class="delete-btn">删除</a-button>
                                    </a-popconfirm>
                                </div>
                            </template>
                            
                        </td>
                        <!-- 删除 加精，热门 审核 -->
                        <!-- 删除 审核 -->
                    </tr>
                </tbody>
            </table>
            <a-empty v-if="list.length == 0"/>
        </a-spin>
        <Pagination
            :pageSize="params.pageSize"
            :total="total"
            @pageChange="pageChange"
            v-if="list.length>0"
            :current="params.pageIndex"
        />

        <!-- 弹窗新手信息 -->
        <a-modal
            :title="dialogItem.ParentId == 0 ? '帖子':'回复'"
            :visible="modalVisable"
            :confirm-loading="auditLoading"
            okText="通过审核"
            @ok="confirmPassPosts(dialogItem)"
            @cancel="modalVisable = false"
            :width="800"
        >
            <div class="dialog" v-if="dialogItem">
               <h1>
                    <div class="label">标题：</div>
                    <div class="txt">{{dialogItem.Title}}</div>
               </h1>
                <div class="content">
                    <div class="label">内容：</div>
                    <div class="txt" v-html="dialogItem.Content"></div>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import Pagination from '~/components/common/Pagination'
import { mapState, mapActions, mapMutations } from 'vuex'
// 接口
import { GetList , GetAuditList , Del , Good , Hot ,Audit } from '@/api/forum'
export default {
    props:{
        tabId:Number,
        subjectIds:String,
        keyword:String
    },
    components:{
        Pagination
    },
    data() {
        return {
            list:[],
            params:{
                pageIndex:1,
                pageSize:10,
                orderBy:'Sort desc',
                isHot:'',
                key:'',
                subjectId:''
            },
            total:0,
            spinning:true,

            cate: [],

            // loading
            deleteLoading:false,
            hotLoading:false,
            goodLoading:false,
            auditLoading:false,


            // 弹窗显示
            modalVisable:false,
            dialogItem:{}
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo,
            subjectListNoTree:state => state.model.subjectListNoTree,
        }),
    },
    watch:{
        // tabId:{
        //     handler(newval,oldval){
        //         this.params.pageIndex = 1
        //         if(newval == 0){
        //             this.params.orderBy = 'Sort desc'
        //             this.params.isHot = ''
        //             this.getPostsList()
        //         }else if(newval == 1){
        //             this.params.orderBy = 'LastTime desc'
        //             this.params.isHot = ''
        //             this.getPostsList()
        //         }else if(newval == 2){
        //             this.params.orderBy = ''
        //             this.params.isHot = '1'
        //             this.getPostsList()
        //         }
        //     }
        // },
        subjectIds:{
            handler(newval,oldval){
                this.params.subjectId = newval
                 if(this.tabId  == 1){
                    this.params.orderBy = 'LastTime desc'
                }
                if(this.tabId == 2){
                    this.params.isHot = '1'
                }
                
                if(this.tabId == 5) {
                    this.getAuditList()
                }else{
                    this.getPostsList()
                }
                
                
            },
            immediate:true
        },
        keyword:{
            handler(newval,oldval){
                this.params.key = newval
                if(this.tabId == 5) {
                    this.getAuditList()
                }else{
                    this.getPostsList()
                }
            }
        }
    },
    methods:{
        pageChange({page,pageSize}){
            this.params.pageIndex = page
            if(this.tabId == 5) {
                this.getAuditList()
            }else{
                this.getPostsList()
            }
        },
        // 详情
        goDetail(item){
            if(this.tabId == 5){
                this.dialogItem = item
                console.log(item);
                this.modalVisable = true
            }else{
                this.$router.push({name:'forum-info',params:{id:item.Id}})
            }
            
        },
        // 获取列表
        async getPostsList(){
            this.spinning = true
            const { data , total } = await GetList(this.params)
            data.forEach(element => {
                element.deleteLoading = false
                element.hotLoading = false
                element.goodLoading = false
                element.auditLoading = false
            });
            this.list = data
            this.total = total
            this.spinning = false
        },
        // 获取审核列表
        async getAuditList(){
            this.spinning = true
            const { data , total } = await GetAuditList(this.params)
            data.forEach(element => {
                element.deleteLoading = false
                element.hotLoading = false
                element.goodLoading = false
                element.auditLoading = false
            });
            this.list = data
            this.total = total
            this.spinning = false
        },

        // 删除帖子
        confirmDeletePosts(item){
            item.deleteLoading = true
            Del({id:item.Id}).then(res => {
                this.$message.success(res.msg)
                if(this.tabId == 5){
                    this.getAuditList()
                }else{
                    this.getPostsList()
                }
                
            }).catch(err => {
                console.log(err);
                item.deleteLoading = false
            })
        },
        // 审核帖子
        confirmPassPosts(item){
            item.auditLoading = true
            Audit({id:item.Id}).then(res => {
                this.$message.success(res.msg)
                this.modalVisable = false
                if(this.tabId == 5){
                    this.getAuditList()
                }else{
                    this.getPostsList()
                }
                
            }).catch(err => {
                console.log(err);
                item.auditLoading = false
            })
        },
        // 热门
        confirmHotPosts(item){
            item.hotLoading = true
            Hot({id:item.Id}).then(res => {
                this.$message.success(res.msg)
                if(this.tabId == 5) {
                    this.getAuditList()
                }else{
                    this.getPostsList()
                }
            }).catch(err => {
                console.log(err);
                item.hotLoading = false
            })
        },
        // 加精
        confirmRefinePosts(item){
            item.goodLoading = true
            Good({id:item.Id}).then(res => {
                this.$message.success(res.msg)
                if(this.tabId == 5) {
                    this.getAuditList()
                }else{
                    this.getPostsList()
                }
            }).catch(err => {
                console.log(err);
                item.goodLoading = false
            })
        }
    },
    created(){
        // if(this.tabId  == 1){
        //     this.params.orderBy = 'LastTime desc'
        // }
        // if(this.tabId == 2){
        //     this.params.isHot == '1'
        // }
        
    }
    
}
</script>
<style scoped lang="less">
@import url('~@/assets/css/personal.less');
td{
    text-align: center !important;
}
.title{
    text-align: left !important;
    display: flex;
    width: 100%;
    &>span{
        cursor: pointer;
        height: 1.5em;
        &:hover{
            color: #f49133;
        }
    }
    .dot{
        flex: 1;
    }
    .tag_wrap{
        flex-shrink: 0;
        min-width: 60px;
        padding-right: 5px;
        span{
            display: inline-block;
            padding: 0 10px;
            line-height: 30px;
            border-radius: 10px 0 10px 0;
            background-color: #f49133;
            color: #fff;
        }
    }

}
.time{
    color: rgb(136, 136, 136) ;
}
:deep(.pagenation){
    padding-top: 45px;
    margin-top: 0;
}
.index{
    span{
        min-width: 24px;
        border-radius: 5px;
        height: 24px;
        line-height: 24px;
        background-color: rgb(245, 245, 245);
        color: rgb(136, 136, 136);
        display: inline-block;
    }
    .index1{
        background-color: #ff0000;
        color: #fff;
    }
    .index2{
        background-color: #ff8181;
        color: #fff;
    }
    .index3{
        background-color: #ff9999;
        color: #fff;
    }
}
:deep(.ant-tag ){
    cursor: pointer;
}
// .handle{
//     display: flex;
//     justify-content: center;
//     span{
//         margin: 0 3px;
//         font-size: 14Px;
//         cursor: pointer;
//         color: #999;
//         &:hover{
//             color: #f49133;
//         }
//     }
// }
.handle{
    padding-right: 0 !important;
    &>div{
        display: flex;
        justify-content: center;
        :deep(.ant-btn){
            border-color: #79f9d9;
            margin: 0 .02rem;
            background-color: #00ffc0;
            color: #000000;
            font-size: 12Px;
            height: 25px;
            padding: 0 8px;
            min-width: 60px;
            &:hover{
                background-color: #79f9d9;
                border-color: #79f9d9;
                color: #000;
            }
        }
    }
}
:deep(.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only)){
    padding-left: 20px;
}
.delete-btn{
    background-color: red !important;
    border-color: red !important;
    color: #fff !important;
}
.dialog{
    h1{
        margin-bottom: .2rem;
        display: flex;
        .label{
            width: 60px;
            flex-shrink: 0;
            font-size: 20px;
        }
        .txt{
            flex: 1;
            font-size: 20px;
        }
    }
    .content{
        display: flex;
        .label{
            width: 60px;
            flex-shrink: 0;
            font-size: 20px;
        }
        .txt{
            flex: 1;
            max-height: 4rem;
            overflow-y: auto;
        }
    }
}
</style>