<template>
    <div class="tab_list">
        <template v-for="item in tabList">
            <span
                :key="item.value"
                v-if="item.value != 5"
                :class="item.value == curTab? 'active':''"
                @click="tabChange(item)"
            >{{item.name}}</span>

            <span
                :key="item.value"
                v-if="item.value == 5 && userInfo.IsSpecial"
                :class="item.value == curTab? 'active':''"
                @click="tabChange(item)"
            >{{item.name}}</span>
        </template>
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
    props:['tabList','curTab'],
    data() {
        return {

        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo,
            subjectListNoTree:state => state.model.subjectListNoTree,
        }),
    },
    methods:{
        tabChange(item){
            this.$emit('change',item.value)
        } 
    }
}
</script>
<style scoped lang="less">
.tab_list{
    display: flex;
    span{
        padding: 0 24px;
        line-height: 50px;
        border-radius: 8px 8px 0 0 ;
        background-color: #f5f5f5;
        margin-right: 5px;
        font-size: 18px;
        color: rgb(136, 136, 136);
        transition: background-color .3s;
        cursor: pointer;
        &:hover,
        &.active{
            color: rgb(51, 51, 51);
            background-color: #e5e5e5;
        }
    }
}


</style>