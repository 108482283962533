<template>
    <div class="forum-list">
        <bg-top>
            <Nav @change="subjectChange"/>
        </bg-top>
        <HotNews/>
        <div class="auto-1440">
            <Search  @search="search"/>
            <div class="tab">
                <Tab :tabList="tabList" :curTab="curTab" @change="tabChange"/>
                <div class="publish" @click="$router.push({name:'publish'})">
                    <svg-icon icon-class="icon_publish"></svg-icon>
                    <span>发布新帖</span>
                </div>
            </div>
            <div class="list_wrap">
                <!-- <transition-group name="transform" mode="out-in"> -->
                    <List 
                        v-if="curTab == 0"
                        key="0"
                        :tabId="0"
                        :subjectIds="subjectIds"
                        :keyword="key"
                    />
                    <List 
                        v-if="curTab == 1"
                        key="1"
                        :tabId="1"
                        :subjectIds="subjectIds"
                        :keyword="key"
                    />
                    <List 
                        v-if="curTab == 2"
                        key="2"
                        :tabId="2"
                        :subjectIds="subjectIds"
                        :keyword="key"
                    />
                    <List 
                        v-if="curTab == 5"
                        key="5"
                        :tabId="5"
                        :subjectIds="subjectIds"
                        :keyword="key"
                    />
                    <UserList 
                        key="3"
                        v-if="curTab == 3 "
                        :tabId="3"
                        :subjectIds="subjectIds"
                        :keyword="key"
                    />
                    <UserList 
                        key="4"
                        v-if=" curTab == 4"
                        :tabId="4"
                        :subjectIds="subjectIds"
                        :keyword="key"
                    />
                <!-- </transition-group> -->
                
            </div>
        </div>
    </div>
</template>
<script>
import BgTop from '~/components/common/BgTop'
import Nav from '~/components/Learning/List/Nav'
import HotNews from '@/components/Forum/List/HotNews'
import Search from '@/components/Forum/List/Search'
import Tab from '@/components/Forum/List/Tab'
import List from '@/components/Forum/List/List'
import UserList from '@/components/Forum/List/UserList'

// 接口
import { GetList } from '@/api/forum'
export default {
    metaInfo() {
		return {
			title: '学习交流论坛-文物出版社',
			meta: [
                {
                    name: 'keyWords',
					content: '学习交流论坛-文物出版社'
                },
                {
                    name: 'description',
					content: '学习交流论坛-文物出版社'
                }
            ]
		}
	},
    components:{
        BgTop,
        Nav,
        HotNews,
        Search,
        Tab,
        List,
        UserList
    },
    data() {
        return {
            tabList:[
                { name:'最新主题',  value:0 },
                { name:'最新回复',  value:1 },
                { name:'热门主题',  value:2 },
                { name:'发帖排行',  value:3 },
                { name:'积分排行',  value:4 },
                { name:'待审核',  value:5 },
            ],
            curTab:0,
            subjectIds:'',
            key:''
        }
    },
    methods:{
        tabChange(tab){
            this.curTab = tab
        },
        // zhuti
        subjectChange(ids){
            this.subjectIds = ids.join(',')
        },
        // 搜索
        search(val){
            console.log(val);
            if(val.Type == 0){
                if(this.curTab !== 0 && this.curTab !== 1 && this.curTab !== 2){
                    this.curTab = 0
                }
            }else{
                if(this.curTab !== 3 && this.curTab !== 4){
                    this.curTab = 3
                } 
            }
            this.key = val.value
        },

       
    }
}
</script>
<style lang="less" scoped>
.auto-1440{
    padding-top: 40px;
    padding-bottom: 90px;
}
.tab{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .publish{
        display: flex;
        align-content: center;
        font-size: 18px;
        color: rgb(244, 145, 51);
        font-weight: bold;
        cursor: pointer;
        .svg-icon{
            margin-right: 10px;
        }
        span{
            line-height: 1;
        }
    }
}
/* fade-transform */
.transform-leave-active,
.transform-enter-active {
  transition: all .5s;
}

.transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>