<template>
    <div class="hot" v-show="isshowHot">
        <div class="auto-1440">
            <div class="label">
                <svg-icon icon-class="icon_hot"></svg-icon>
                <span>热门</span>
            </div>
            <div class="swiper" v-swiper:mySwiper="swiperOption" :class="list.length == 1 ? 'swiper-no-swiping':''" v-if="list.length > 0">
                <div class="swiper-wrapper" >
                    <div class="swiper-slide" v-for="item in list" :key="item.Id">
                        <a class="dot" @click="goDetail(item)">{{item.Title}}</a>
                        <div class="time">{{item.CreateTime.split(' ')[0].split('/').join('-')}}</div>
                        <!-- <div class="close">
                            <a-tooltip title="关闭热门">
                                <svg-icon icon-class="icon_close"></svg-icon>
                            </a-tooltip>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="close">
                <a-tooltip title="关闭热门">
                    <svg-icon icon-class="icon_close" @click.native="closeHot"></svg-icon>
                </a-tooltip>
            </div>
        </div>
    </div>
</template>
<script>
import { GetList } from '@/api/forum'
export default {
    data(){
        return{
            list:[],
            swiperOption: {
                loop: true,
                slidesPerView: 1,
                autoplay:true,
                direction : 'vertical',
            },
            params:{
                pageIndex:1,
                pageSize:5,
                isHot:'1'
            },
            isshowHot:true
        }
    },
    methods:{
        // 获取热门列表
        async getHotList(){
            const { data } = await GetList(this.params)
            this.list = data
        },
        closeHot(){
            console.log(1);
            this.isshowHot = false
        },
        // 详情
        goDetail(item){
            this.$router.push({name:'forum-info',params:{id:item.Id}})
        },
    },
    mounted(){
        this.getHotList()
    }
}
</script>
<style lang="less" scoped>
.hot{
    background-color: #f5f5f5;
    .auto-1440{
        display: flex;
        align-items: center;
        position: relative;
    }
    .label{
        display: flex;
        align-items: center;
        font-size: 20px;
        color: rgb(255, 0, 0);
        font-weight: bold;
        margin-right: 40px;
        .svg-icon{
            margin-right: 10px;
        }
    }
    .swiper{
        flex: 1;
        height: 80px !important;
        .swiper-slide{
           height: 80px !important;
           display: flex;
           align-items: center;
           justify-content: space-between;
           padding-right: 70px;
           position: relative;
        }
        .dot{
            font-size: 18px;
            color: rgb(85, 85, 85);
            &:hover{
                color: #00ffc0;
            }
        }
        .time{
            font-size: 16px;
            color: rgb(136, 136, 136);
        }
        
    }
    .close{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: #bbbbbb;
        cursor: pointer;
        z-index: 2;
        .svg-icon{
            line-height: 1;
            display: block;
            transition: .3s;
            &:hover{
                color: #666;
            }
        }
    }
}

</style>